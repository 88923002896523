import {React, useEffect, useRef} from 'react'
import axios from 'axios';
import { useState } from 'react';
import Api from '../Apis';
import Load from '../Loading';
import { PiPlaceholder } from 'react-icons/pi';

export const GetAllStock = () => {

    const [datas,setDatas] = useState()
    const [number,setNumber] = useState(0)
    const timeoutRef = useRef(null);
    const [rowNumber, setRowNumber ] = useState(0)
    const [isLoading,setIsLoading] = useState(false)
    const [searchProduct,setSearchProduct] = useState("")
    const [lastKeyPressTime, setLastKeyPressTime] = useState(null);

    const getAllDatas = () => {
        setIsLoading(true)
        axios.get(Api.Report.value + "/getAllProducts/" + number,{headers: Api.Token}).then(res => {
            setDatas(res.data)
            console.log(res.data)
            setIsLoading(false)
        }).catch(()=>{
            localStorage.removeItem("token");
            window.location.replace("/login")
            alert("Something went wrong in authentication. please re-login")
        })

    }
    const getNumberOfit = () => {
        setIsLoading(true)
        axios.get(Api.Report.value + "/getAllNumberProducts",{headers: Api.Token}).then(res => {
            setRowNumber(res.data)
            console.log(res.data)
            setIsLoading(false)
        }).catch(()=>{
            localStorage.removeItem("token");
            window.location.replace("/login")
            alert("Something went wrong in authentication. please re-login")
        })
    }

    const handleKeyPress = () => {
        const currentTime = new Date().getTime();
        setLastKeyPressTime(currentTime);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            // console.log(searchProduct.length)
            // console.log(searchProduct)
            if(searchProduct && searchProduct.length >2){
            setIsLoading(true)
            axios.get(Api.Report.value + "/getAllProductsWithProduct/"+ searchProduct,{headers: Api.Token}).then(res=>{
                setDatas(res.data)
                setSearchProduct("")
                setIsLoading(false)
            }).catch(()=>{
                window.location.replace("/login")
                alert("Something went wrong in authentication. please re-login")
            })}
        }, 2000);
    };
    

    useEffect(() => {
        getAllDatas()
        getNumberOfit()

        return () => {
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
            }
        };
    },[number])

    const dropdownDivStyle ={
        display:"none"
    }
    const dropdownDivStyle2 = {
        display: "block"
    }
    return(<>
        <div className="container mt-1 bg-light">
            <div className="row">
                <div className="col-12">
                    <h3 className='text-capitalize h3 text-center  fw-bold' style={{fontFamily:"times-new-roman"}}>All products </h3>
                    <form className="form was-validated my-2">
                        <div className="col-md-5">
                            <label htmlFor="" className="form-label">Search single product</label><br />
                            <input
                                type="text" required
                                onChange={e=>setSearchProduct(e.target.value)}
                                onKeyUp={handleKeyPress}
                                value={searchProduct}
                                PiPlaceholder="enter product"
                                className="form-control"
                                
                            />
                        </div>
                    </form>
                    <p className='h7 fw-bold text-capitalize'>number of products are {rowNumber}</p>
                    <table className='table table-sm table-bordered' style={{fontSize:"15px"}}>
                        <thead className='bg-dark text-light text-capitalize'>
                            <tr>
                                <th>n<sup>o</sup></th>
                                <th>product name</th>
                                <th>Product Category</th>
                                <th>product type</th>
                                <th>Quantity remain</th>
                                <th>minimum price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                datas && datas.map((data,index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{ index + 1}</td>
                                            <td>{data.product_name}</td>
                                            <td>{data.category_name}</td>
                                            <td>{data.type_name}</td>
                                            <td>{data.quantity_remain}</td>
                                            <td>{data.min_price} Rwf</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <div className='mb-2'>
                        <button onClick={() => setNumber(0)} className='btn btn-primary mx-2'>Begin</button>
                        <button onClick={()=> number >0 ? setNumber(number-1):setNumber(number)} className='btn btn-primary mx-2'>Previous</button>
                        <button onClick={() => number <Math.round(rowNumber/10)?setNumber(number+1):setNumber(Math.round(rowNumber/10))} className='btn btn-primary mx-2'>Next</button>
                        <button onClick={() => setNumber(Math.round(rowNumber/10))} className='btn btn-primary mx-2'>End</button>
                        
                    </div>
                </div>
            </div>
            <div style={isLoading? dropdownDivStyle2:dropdownDivStyle}>
                <Load />
            </div>
        </div>
        
    </>)

}