import axios, { Axios } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Api from './Apis';
import Load from './Loading';

const ImportsForm = () => {
    const [lastKeyPressTime, setLastKeyPressTime] = useState(null);
    const timeoutRef = useRef(null);
    const [productComponents,setProductComponents] = useState([])
    const [isClicked,setIsClicked] = useState(true)
    const [searchProduct,setSearchProduct] = useState("")
    const [selectedIndex,setSelectedIndex] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [inputs, setInputs] = useState([
        {
            "productId": 0,
            "amount_used": 0,
            "quantity_used": 0,
            "minimum_amount_sold": 0
          }
    ]);
    const [currentPage, setCurrentPage] = useState(0);

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedInputs = [...inputs];
        updatedInputs[index] = { ...updatedInputs[index], [name]: value };
        setInputs(updatedInputs);
    };

    const addInputGroup = () => {
        setInputs([
            ...inputs,
            {
                "productId": 0,
                "amount_used": 0,
                "quantity_used": 0,
                "minimum_amount_sold": 0
              }
        ]);
        setCurrentPage(inputs.length);
        // setProductComponents([])
    };

    const removeInputGroup = (index) => {
        const updatedInputs = inputs.filter((_, i) => i !== index);
        setInputs(updatedInputs);
        setCurrentPage(Math.max(0, currentPage - 1));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(inputs);
        // alert(inputs)
        setIsLoading(true)
        axios.post(Api.StockAction.value + "/import", inputs, {headers: Api.Token}).then(res=> {
            alert(res.data)
            setInputs([
                {
                    "productId": 0,
                    "amount_used": 0,
                    "quantity_used": 0,
                    "minimum_amount_sold": 0
                  }
            ])
            setIsLoading(false)
        }).catch(()=>{
                localStorage.removeItem("token");
                window.location.replace("/login")
                alert("Something went wrong in authentication. please re-login")
            })
        // Add your form submission logic here
    };

    const goToPage = (pageIndex) => {
        setCurrentPage(pageIndex);
    };

    const handleKeyPress = () => {
        const currentTime = new Date().getTime();
        setLastKeyPressTime(currentTime);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            // console.log(searchProduct.length)
            // console.log(searchProduct)
            if(searchProduct && searchProduct.length >2){
            setIsLoading(true)
            axios.get(Api.StockAction.value + "/searchQuery/"+ searchProduct,{headers: Api.Token}).then(res=>{
                setProductComponents(res.data)
                setIsLoading(false)
            }).catch(()=>{
                localStorage.removeItem("token");
                window.location.replace("/login")
                alert("Something went wrong in authentication. please re-login")
            })}
        }, 2000);
    };

    useEffect(() => {
        // handleProductChange()

        return () => {
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
            }
        };
    },[])
    const dropdownDivStyle ={
        display:"none"
    }
    const dropdownDivStyle2 = {
        display: "block"
    }
    return (
        <div>
            <form onSubmit={handleSubmit} className='form was-validated'>
                <div className='container bg-light mt-4 rounded'>
                    <h2 className='text-center'>Import New Products</h2>
                    <div className="row">
                        {/* <div className="col-md-3"></div> */}
                        
                        <div className="col-md-6 bg-light">
                                {inputs.map((inputGroup, index) => (
                                    index === currentPage && (
                                        <div key={index} >
                                            <div className="row d-flex">
                                                <label className="form-label">Product</label>
                                                    <div>
                                                        <input
                                                            type="text" required
                                                            onChange={e=>setSearchProduct(e.target.value)}
                                                            onKeyUp={handleKeyPress}
                                                            className="form-control"
                                                            
                                                        />
                                                        <select name="productId" required value={inputGroup.price_id}  onChange={(e) => handleInputChange(index, e)} className="form-select mt-2" id="">
                                                            <option value="">See results</option>
                                                            {
                                                                productComponents.length > 0? productComponents.map((comp,index) =>(
                                                                    <option key={index} value={comp.productPrice_id}>{comp.product_name}- {comp.category_name} - {comp.type_name}</option>
                                                                )):<option>no product</option>
                                                            }
                                                        </select>
                                                    </div>
                                                <div className="col-md-6 first_div">
                                                    <label className="form-label">Quantity</label>
                                                    <input required min="1"
                                                        type="number"
                                                        name="quantity_used"
                                                        value={inputGroup.quantity_used}
                                                        onChange={(e) => handleInputChange(index, e)}
                                                        className="form-control"
                                                    />
                                                </div>
                                                <div className="col-md-6 second_div">
                                                    
                                                    <label className="form-label">Amount Buyed</label>
                                                    <input required min="1"
                                                        type="number"
                                                        name="amount_used"
                                                        value={inputGroup.amount_used}
                                                        onChange={(e) => handleInputChange(index, e)}
                                                        className="form-control"
                                                    />
                                                    <label className="form-label">Minimum Amount To Be Sold</label>
                                                    <input required min="1"
                                                        type="number"
                                                        name="minimum_amount_sold"
                                                        value={inputGroup.minimum_amount_sold}
                                                        onChange={(e) => handleInputChange(index, e)}
                                                        className="form-control"
                                                    />
                                                </div>
                                                
                                            {/* </div> */}
                                            </div>
                                            
                                            <button
                                                type="button"
                                                className="btn btn-danger mt-2"
                                                onClick={() => removeInputGroup(index)}
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    )
                                ))}
                                
                            
                        
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-3">
                                    
                                </div>
                                <div className="col-md-6">
                                    <div className=' col-md mt-3 '>
                                        <button type="button" className="btn btn-primary mt-5 w-100" onClick={addInputGroup}>
                                            Add Input Group
                                        </button><br />
                                        <button type="submit" className="btn mt-5 w-100 btn-success">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                            
                        </div>
                        
                    </div>
                    <div className="pagination mt-3 overflow-scroll">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            disabled={currentPage === 0}
                            onClick={() => goToPage(currentPage - 1)}
                        >
                            Previous
                        </button>
                        {inputs.map((_, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`btn ${currentPage === index ? 'btn-primary' : 'btn-secondary'} mx-1`}
                                onClick={() => goToPage(index)}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button
                            type="button"
                            className="btn btn-secondary"
                            disabled={currentPage === inputs.length - 1}
                            onClick={() => goToPage(currentPage + 1)}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </form>
            <div style={isLoading? dropdownDivStyle2:dropdownDivStyle}>
                <Load />
            </div>
        </div>
    );
};

export default ImportsForm;
