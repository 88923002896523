import { useEffect, useState } from "react"
import Api from "../Apis"
import axios from "axios"
import Load from "../Loading"

export const ToDayTradings = () => {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const fullMonth = month<10?`0${month}`:month
    const day = date.getDate() <10?`0${date.getDate()}`:date.getDate()
    const [allTodayReports,setTodayAllReports] = useState(null)
    const [total,setTotal] = useState(0)
    const [isLoading,setIsLoading] = useState(false)
    const [dates,setDate] = useState("")
    const [fullDate,setFullDate] = useState(`${year}-${fullMonth}-${day}`)
    // const [fullDate2,setFullDate2] = useState()
        
    
    const getAllTodayTradings = () => {
        setIsLoading(true)
        // alert(fullDate)
        axios.get(Api.Report.value + "/getToDayReport/"+ fullDate,{headers: Api.Token}).then(res=>{
            setTodayAllReports(res.data)
            console.log(res.data)
            setIsLoading(false)
        }).catch(()=>{
            localStorage.removeItem("token");
            window.location.replace("/login")
            alert("back to login")
        })
    }

    useEffect(() => {
        getAllTodayTradings()
        // setingToDayDate()
        // alert("usingEffect")
    },[fullDate])

    const handleSubmit = (e) => {
        e.preventDefault()
        
        // setIsLoading(true)
        const newDates = new Date(dates)
        const newDate = new Date(`${year}-${fullMonth}-${day}`)
        // newDates.setDate(newDate.getDate() +1)
        if(newDates.getTime() > newDate.getTime()){
            alert("You are searching for future date.")
        }else{
            setFullDate(dates)
        }

        // alert(dates)
    }
    const dropdownDivStyle ={
        display:"none"
    }
    const dropdownDivStyle2 = {
        display: "block"
    }
    var totalSales = 0;
    var totalProfit = 0;

    

    return (
        <>
            <form className="form was-validated my-2"   onSubmit={handleSubmit}>
                <div className="d-flex">
                    <input type="date" value={dates} onChange={e=>setDate(e.target.value)} required minLength={1} className="form-control me-2 w-25" />
                    <button className="btn btn-sm btn-primary">send</button>
                </div>
            </form>
            <div className="table-responsive">
                {
                    allTodayReports && allTodayReports.map((dailyData,index) =>{
                        totalProfit += dailyData.profit
                        totalSales += dailyData.amount_sell;
                    
                    })
                }
                <table className="table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Total sales Amount</th>
                            <th>Total profit</th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr>
                            <td>{fullDate}</td>
                            <td>{totalSales}</td>
                            <td>{totalProfit}</td>
                        </tr>
                    </tbody>
                </table>
                <table className="table table-hover table-sm table-striped table-bordered">
                    <thead className="bg-dark text-light">
                        <tr>
                            <th>Product name</th>
                            <th>Product category</th>
                            <th>Product type</th>
                            <th>Opening stock</th>
                            <th>purchase quantity</th>
                            <th>purchase amount</th>
                            <th>sale quantity</th>
                            <th>sale amount</th>
                            <th>Closing stock</th>
                            <th>Total profit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allTodayReports && allTodayReports.map((dailyData,index) =>{
                                
                            return <tr key={index}>
                                        <td>{dailyData.product_name}</td>
                                        <td>{dailyData.category_name}</td>
                                        <td>{dailyData.type_name}</td>
                                        <td>{dailyData.opening_stock}</td>
                                        <td>{dailyData.quantity_suply}</td>
                                        <td>{dailyData.amount_suply}</td>
                                        <td>{dailyData.quantity_sell}</td>
                                        <td>{dailyData.amount_sell}</td>
                                        <td>{dailyData.closing_stock}</td>
                                        <td>{dailyData.profit}</td>
                                    </tr>
                            })
                        }
                        
                    </tbody>
                </table>
                
                <div style={isLoading? dropdownDivStyle2:dropdownDivStyle}>
                    <Load />
                </div>
            </div>
        </>
    )
}