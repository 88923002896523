import { useEffect, useState } from "react"
import "./css/ProductAction.css"
import axios from "axios"
import Api from "./Apis"
const ProductActions = () => {
    const [productActions, setProductActions] = useState()

    const getProductActions= () => {
        axios.get(Api.StockAction.value + "/getAllValues/"+ 5,{headers: Api.Token}).then(res=>{
            setProductActions(res.data)
            console.log(res.data)
        }).catch(()=>{
            localStorage.removeItem("token");
            window.location.replace("/login")
            alert("back to login")
        })
    }
    useEffect(()=>{
        getProductActions()
    },[])
    return <>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="mainActionContainer">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="actionHead">Product Actions</h2>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-12 productTable">
                                <table className="table table-hover table-sm table-bordered">
                                    <thead className="bg-dark text-light">
                                    <tr>
                                        <th>Product Name</th>
                                        <th>Category Name</th>
                                        <th>Type Name</th>
                                        <th>Date Done</th>
                                        <th>Action</th>
                                        <th>Quantity In</th>
                                        <th>Quantity_used</th>
                                        <th>Quantity_remain</th>
                                        <th>Amount_used</th>
                                        
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            productActions && productActions.map((product,index) => (
                                                <tr key={index}>
                                                    <td>{product.product_in_actions.price_in_product.product_name}</td>
                                                    <td>{product.product_in_actions.price_in_category.category_name}</td>
                                                    <td>{product.product_in_actions.price_in_type.type_name}</td>
                                                    <td>{product.date_done}</td>
                                                    <td>{product.action}</td>
                                                    <td>{product.quantity_in}</td>
                                                    <td>{product.quantity_used}</td>
                                                    <td>{product.quantity_remain}</td>
                                                    <td>{product.amount_used} Rwf</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ProductActions