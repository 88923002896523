import { useEffect, useState } from "react";
import Api from "./Apis";
import axios from "axios";
import Load from "./Loading"

const ExportMultiple = () => {
    const [productComponents,setProductComponents] = useState([])
    const [isLoading,setIsLoading] = useState(false)
    const [allExpenses,setAllExpenses] = useState()
    const [autoRefresh,setAutoRefresh] = useState(false)
    const [inputs, setInputs] = useState([
        {
            expense_name: "",
            amount: ""
        }
    ]);
    const [currentPage, setCurrentPage] = useState(0);

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedInputs = [...inputs];
        updatedInputs[index] = { ...updatedInputs[index], [name]: value };
        setInputs(updatedInputs);
    };

    const addInputGroup = () => {
        setInputs([
            ...inputs,
            {
                expense_name: "",
                amount: ""
            }
        ]);
        setCurrentPage(inputs.length);
        // setProductComponents([])
    };

    const removeInputGroup = (index) => {
        const updatedInputs = inputs.filter((_, i) => i !== index);
        setInputs(updatedInputs);
        setCurrentPage(Math.max(0, currentPage - 1));
    };

    const handleSubmit = (event) => {
        setIsLoading(true)
        event.preventDefault();
        console.log(inputs);
        axios.post(Api.Expenses.value + "/", inputs,{headers: Api.Token}).then(res=> {
            alert(res.data)
            setIsLoading(false)
            setAutoRefresh(!autoRefresh)
            setInputs([
                {
                    expense_name: "",
                    amount: ""
                }
            ])
        }).catch(()=>{
            localStorage.removeItem("token");
            window.location.replace("/login")
            alert("Something went wrong in authentication. please re-login")
        })
        // Add your form submission logic here
    };

    const goToPage = (pageIndex) => {
        setCurrentPage(pageIndex);
    };

    const getAll = () => {
        setIsLoading(true)
        axios.get(Api.Expenses.value + "/getAllByDate",{headers:Api.Token}).then(res=>{
            setAllExpenses(res.data)
            console.log(res.data)
            setIsLoading(false)
        }).catch(()=>{
            localStorage.removeItem("token");
            window.location.replace("/login")
            alert("Something went wrong in authentication. please re-login")
        })
    }

    useEffect(() => {
        getAll()
    },[autoRefresh])

    const handleDelete = (e,id) => {
        e.preventDefault()
        setIsLoading(true)
        
        axios.delete(Api.Expenses.value + "/deleteExpense/"+ id,{headers:Api.Token}).then(res=>{
                console.log(res.data)
                alert("done")
                setIsLoading(false)
                setAutoRefresh(!autoRefresh)
            }
        ).catch(()=>{
            localStorage.removeItem("token");
            window.location.replace("/login")
            alert("Something went wrong in authentication. please re-login")
        })
    }

    const dropdownDivStyle ={
        display:"none"
    }
    const dropdownDivStyle2 = {
        display: "block"
    }
    return (
        <div>
           <div className="container bg-light">
            <div className="row">
            <form onSubmit={handleSubmit} className='form was-validated'>
            <div className='container mt-4 rounded'>
                <h2 className='text-center'>Expenses</h2>
                <div className="row">
                    {/* <div className="col-md-3"></div> */}
                    <div className="col-md-6">
                        
                            {inputs.map((inputGroup, index) => (
                                index === currentPage && (
                                    <div key={index} >
                                        <div className="row d-flex">
                                            <label className="form-label">Expense Name</label>
                                                <div>
                                                    <input
                                                        type="text" name="expense_name" required
                                                        onChange={e=>handleInputChange(index,e)}
                                                        className="form-control"
                                                        
                                                        />
                                                    <label className="form-label mt-2">Amount</label>
                                                    <input 
                                                    type="text"
                                                    name="amount"
                                                    onChange={e =>handleInputChange(index,e)}
                                                    className="form-control" 
                                                    required  
                                                    />
                                                </div>
                                        {/* </div> */}
                                        </div>
                                        
                                        <button
                                            type="button"
                                            className="btn btn-danger mt-2"
                                            onClick={() => removeInputGroup(index)}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                )
                            ))}
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-3">
                                
                            </div>
                            <div className="col-md-6">
                                <div className='mt-4'>
                                    <button type="button" className="btn btn-primary mt-2 w-100" onClick={addInputGroup}>
                                        Add Input Group
                                    </button><br />
                                    <button type="submit" className="btn mt-2 w-100 btn-success">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
                <div className="pagination mt-3 overflow-scroll">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        disabled={currentPage === 0}
                        onClick={() => goToPage(currentPage - 1)}
                    >
                        Previous
                    </button>
                    {inputs.map((_, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`btn ${currentPage === index ? 'btn-primary' : 'btn-secondary'} mx-1`}
                            onClick={() => goToPage(index)}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button
                        type="button"
                        className="btn btn-secondary"
                        disabled={currentPage === inputs.length - 1}
                        onClick={() => goToPage(currentPage + 1)}
                    >
                        Next
                    </button>
                </div>
            </div>

            </form>
            
            <div className="overflow-scroll"style={{height:"25em"}}>
                <table className="table" >
                    <thead>
                        <tr>
                            <th>date</th>
                            <th>expense name</th>
                            <th>amount</th>
                            <th>action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allExpenses && allExpenses.map((data,index) =>(
                                <tr>
                                    <td>{data.date_done}</td>
                                    <td>{data.expense_name}</td>
                                    <td>{data.amount} rf</td>
                                    <td><button
                                        className="btn btn-primary btn-sm"
                                    onClick={e =>{handleDelete(e,data.id)}}>Delete</button></td>
                                </tr>

                            ))
                        }
                    </tbody>
                </table>
            </div>
            
            <div style={isLoading? dropdownDivStyle2:dropdownDivStyle}>
                    <Load />
            </div>
            </div>
           </div>
        </div>
    );
}

export default ExportMultiple