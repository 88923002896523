import { useEffect, useState } from "react";
import { ToDayTradings } from "./TodayTradings";
import { MonthlyReport } from "./MonthlyReport";
import { GetAllStock } from "./GetAllStock";

const Report = () => {

    const localDate = new Date();
    let year = localDate.getFullYear();
    let month = localDate.getMonth();

    month = month < 10 ? `0${month}`:month

    console.log(year + "-" + month)

    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [date,setDate] = useState(0)
    const [date2,setDate2] = useState(month)
    const [isDaily,setIsDaily] = useState(1)

    useEffect(() => {
        const handleResize = () => {
            setScreenHeight(window.innerHeight);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault()
        setDate2(date)
        setDate(0)
    }
    return(
        <div className="mt-2 bg-light container">
            <div className="row">
                <div className="col-12">
                    
                    <div className="d-flex m-2">
                        <button className="btn btn-primary me-2 btn-sm" onClick={() =>setIsDaily(1)}>daily</button>
                        {/* <button className="btn btn-primary me-2 btn-sm" onClick={() =>setIsDaily(0)}>monthly</button> */}
                        <button className="btn btn-primary me-2 btn-sm" onClick={() =>setIsDaily(2)}>view all products</button>
                        {/* <button className="btn btn-primary me-2 btn-sm">full report</button> */}
                        {/* <form className="form was-validated" style={isDaily == 1?{display:"none"}:{display:"block"}}  onSubmit={handleSubmit}>
                            <div className="d-flex">
                                <input type="month" value={date} onChange={e=>setDate(e.target.value)} required minLength={1} className="form-control me-2" />
                                <button className="btn btn-sm btn-primary">send</button>
                            </div>
                        </form> */}
                    </div>
                    {
                        isDaily == 1 ? <>
                            <ToDayTradings />
                        </>:isDaily == 2?<>
                        <GetAllStock />
                        </>:<>
                            <MonthlyReport date = {date2} />
                        </>
                    }
                    
                </div>
            </div>
        </div>
    )
}

export default Report;