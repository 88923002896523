import axios, { Axios } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Api from './Apis';
import Load from './Loading';

const ExportMultiple2 = () => {
    const [lastKeyPressTime, setLastKeyPressTime] = useState(null);
    const timeoutRef = useRef(null);
    const [productComponents,setProductComponents] = useState([])
    const [isClicked,setIsClicked] = useState(true)
    const [searchProduct,setSearchProduct] = useState("")
    const [selectedIndex,setSelectedIndex] = useState()
    const [returnedSubmition,setReturnedSubmition] = useState()
    const [openCloseSubmitionValues,setOpenCloseSubmitionValues] = useState(0)
    const [isLoading,setIsLoading] = useState(false)
    const [inputs, setInputs] = useState([
        {
            "productId": 0,
            "amount_used": 0,
            "quantity_used": 0
          }
    ]);
    const [currentPage, setCurrentPage] = useState(0);

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedInputs = [...inputs];
        updatedInputs[index] = { ...updatedInputs[index], [name]: value };
        setInputs(updatedInputs);
    };

    const addInputGroup = () => {

        setInputs([
            ...inputs,
            {
                "productId": 0,
                "amount_used": 0,
                "quantity_used": 0
              }
        ]);
        setCurrentPage(inputs.length);
        setProductComponents([])
        setSearchProduct("")
    };

    const removeInputGroup = (index) => {
        const updatedInputs = inputs.filter((_, i) => i !== index);
        setInputs(updatedInputs);
        setCurrentPage(Math.max(0, currentPage - 1));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true)
        console.log(inputs);
        axios.post(Api.StockAction.value + "/export", inputs,{headers: Api.Token}).then(res=> {
            setReturnedSubmition(res.data)
            if(res.data == null){
                alert("done")
            }
            else{
                
                setOpenCloseSubmitionValues(1)
                // alert(returnedSubmition)
            }

            setInputs([
                {
                    "productId": 0,
                    "amount_used": 0,
                    "quantity_used": 0
                  }
            ]);
            setProductComponents([])
            setSearchProduct("")
            setIsLoading(false)
        }).catch(()=>{
            localStorage.removeItem("token");
            window.location.replace("/login")
            alert("Something went wrong in authentication. please re-login")
        })
        // window.refles
        // Add your form submission logic here
    };

    const goToPage = (pageIndex) => {
        setCurrentPage(pageIndex);
    };

    const handleKeyPress = () => {
        const currentTime = new Date().getTime();
        setLastKeyPressTime(currentTime);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            // console.log(searchProduct.length)
            // console.log(searchProduct)
            if(searchProduct && searchProduct.length >2){
            setIsLoading(true)
            axios.get(Api.StockAction.value + "/searchQuery/"+ searchProduct,{headers: Api.Token}).then(res=>{
                setProductComponents(res.data)
                setIsLoading(false)
            }).catch(()=>{
                localStorage.removeItem("token");
                window.location.replace("/login")
                alert("Something went wrong in authentication. please re-login")
            })}
        }, 2000);
    };

    useEffect(() => {
        // handleProductChange()

        return () => {
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
            }
        };
    },[])
    const dropdownDivStyle ={
        display:"none"
    }
    const dropdownDivStyle2 = {
        display: "block"
    }
    const displaySubmitedReturns = () => {
        return<>
            <div className='p-2'>
                <h3 className='text-center text-bold'>Results</h3>
                {
                    returnedSubmition && returnedSubmition.map((singleReturn,index) => (
                        <div key={index}>
                            <ul>{
                                    singleReturn.includes("successfully")?<li style={{background:"rgb(135, 247, 135)"}}>
                                        {singleReturn}
                                    </li>:
                                    <li style={{background:"rgb(201, 198, 198)"}}>
                                        {singleReturn}
                                    </li>
                                }
                            </ul>
                        </div>
                    ))
                }
                <button 
                className='btn btn-primary btn-sm w-25' 
                onClick={(e) => {setOpenCloseSubmitionValues(0);}
                }>close</button>
            </div>
        </>
    }
    return (
    <div>
        <form onSubmit={handleSubmit} className='form was-validated'>
            <div className='container bg-light mt-4 rounded'>
                <h2 className='text-center'>Export Products</h2>
                <div className="row">
                    {/* <div className="col-md-3"></div> */}
                    
                    <div className="col-md-6 bg-light">
                            {inputs.map((inputGroup, index) => (
                                index === currentPage && (
                                    <div key={index} >
                                        <div className="row d-flex">
                                            <label className="form-label">Product</label>
                                                <div>
                                                    <input
                                                        type="text" required
                                                        onChange={e=>setSearchProduct(e.target.value)}
                                                        onKeyUp={handleKeyPress}
                                                        value={searchProduct}
                                                        className="form-control"
                                                        
                                                    />
                                                     {/* value={inputGroup.price_id} */}
                                                    <select name="productId" required  onChange={(e) => handleInputChange(index, e)} className="form-select mt-2" id="">
                                                        <option value="">See results</option>
                                                        {
                                                            productComponents.length > 0? productComponents.map((comp,index) =>(
                                                                <option key={index} value={comp.productPrice_id}>{comp.product_name}- {comp.category_name} - {comp.type_name}</option>
                                                            )):<option>no product</option>
                                                        }
                                                    </select>
                                                </div>
                                            <div className="col-md-6 first_div">
                                                <label className="form-label">Quantity</label>
                                                <input required min="1"
                                                    type="number"
                                                    name="quantity_used"
                                                    value={inputGroup.quantity_used}
                                                    onChange={(e) => handleInputChange(index, e)}
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="col-md-6 second_div">
                                                
                                                <label className="form-label">Amount Buyed</label>
                                                <input required min="1"
                                                    type="number"
                                                    name="amount_used"
                                                    value={inputGroup.amount_used}
                                                    onChange={(e) => handleInputChange(index, e)}
                                                    className="form-control"
                                                />
                                            </div>
                                            
                                        {/* </div> */}
                                        </div>
                                        
                                        <button
                                            type="button"
                                            className="btn btn-danger mt-2"
                                            onClick={() => removeInputGroup(index)}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                )
                            ))}
                            
                        
                    
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-3">
                                
                            </div>
                            <div className="col-md-6">
                                <div className=' col-md mt-3 '>
                                    <button type="button" className="btn btn-primary mt-5 w-100" onClick={addInputGroup}>
                                        Add Input Group
                                    </button><br />
                                    <button type="submit" className="btn mt-5 w-100 btn-success">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                    
                </div>
                <div className="pagination mt-3 overflow-scroll">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        disabled={currentPage === 0}
                        onClick={() => goToPage(currentPage - 1)}
                    >
                        Previous
                    </button>
                    {inputs.map((_, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`btn ${currentPage === index ? 'btn-primary' : 'btn-secondary'} mx-1`}
                            onClick={() => goToPage(index)}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button
                        type="button"
                        className="btn btn-secondary"
                        disabled={currentPage === inputs.length - 1}
                        onClick={() => goToPage(currentPage + 1)}
                    >
                        Next
                    </button>
                </div>
            </div>
        </form>
        <div className="container-fluid dropdown" style={openCloseSubmitionValues == 0?dropdownDivStyle:dropdownDivStyle2}>
            <div className="row">
                <div className="col-lg-12 col-md-8 col-sm-10 col-12">
                    <div className="container w-100 dropDiv">{
                        displaySubmitedReturns()
                    }</div>
                </div>
            </div>                
        </div>
        <div style={isLoading? dropdownDivStyle2:dropdownDivStyle}>
            <Load />
        </div>
    </div>
    );
}

export default ExportMultiple2;