import { Link, useHistory } from "react-router-dom"
import "./css/NavCss.css"
import { useEffect, useState } from "react"
import { useSignOut } from "react-auth-kit"

const Navbar = () => {

    const [userName, setUsername] = useState('')
    const [userType, setUserType] = useState('')
    const [navDropDown,setNavDropDown] = useState(false)

    const signOut = useSignOut()
    const logout = () => {
        localStorage.clear()
       

        window.location.replace('/');

    }

    useEffect(() => {
        const theusername = localStorage.getItem('token')
        const theUserType = localStorage.getItem('category')
        if (theusername) {
            setUsername(localStorage.getItem('username'))
        }
        if (theUserType) {
            setUserType(localStorage.getItem('catname'))
        }
    }, [])

    return <>
        <div className="container-fluid main-nav-class">
            <div className="container-fluid main">
                <div className="row d-flex">
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <h1 className="head"><span>C</span>heetah Alimentation</h1>
                    </div>
                    <div className="col-lg-7 col-10">
                        <div className="navs d-flex mt-3">

                            {(userName) && <>
                                <div>
                                    <Link to="/products" className="px-4">Products</Link>
                                </div>

                                <div>
                                    <Link to="/importMultiple" className="px-4">Import</Link>
                                </div>
                                <div>
                                    <Link to="/exportMultiple" className="px-4">Export</Link>
                                </div>
                                <div>
                                    <Link to="/expenses" className="px-4">Expenses</Link>
                                </div>
                                <div>
                                    <Link to="/damaged" className="px-4">Damages</Link>
                                </div>
                                <div>
                                    <Link to="/report" className="px-4">Report</Link>
                                </div>
                                <div className="d-block" id="navPrifile" onMouseOver={()=>setNavDropDown(true)} onMouseOut={()=>setNavDropDown(false)}>
                                    <div>
                                        <Link to="#" className="px-4 text-capitalize">{localStorage.getItem("username")}</Link>
                                    </div>
                                    {
                                        navDropDown?<div className="profileNav" id="profileNav">
                                        
                                        {
                                            localStorage.getItem("category") == "admin"?<div className="ms-4 mt-2">
                                                <Link to="/users" className="display9">Manage Users</Link>
                                            </div>
                                            :<></>
                                        }
                                        <div className="ms-4 mt-2">
                                            <Link to="#" onClick={(e) => logout(e)}>Logout</Link>
                                        </div>
                                    </div>:<></>
                                    }
                                
                                </div>:<></>
                            </>}
                        </div>
                    </div>
                    <div className="col-md-1 col-2">
                        

                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Navbar