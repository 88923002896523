import axios from "axios"
import Api from "../Apis"
import { useEffect, useState } from "react"
import Load from "../Loading";

export const MonthlyReport = (props) => {

    const localDate = new Date();
    let year = localDate.getFullYear();
    let month = localDate.getMonth()+ 1;

    month = month < 10 ? `0${month}`:month
    const fullYear = new Date(`${year}-${month}`);
    const [previousMonth,setPreviousMonth] = useState([])
    const [totalProfit,setTotalProfit] = useState()
    const [monthName, setMonthName] = useState(fullYear.toLocaleString('default', { month: 'long' }))
    const [date,setDate] = useState("")
    const [date2,setDate2] = useState(`${year}-${month}`)
    const [isLoading,setIsLoading] = useState(false)

    const getPreviousMonthReport = () => {
        setIsLoading(true)
        axios.get(Api.Report.value + "/getAllProductsReport/"+ date2,{headers: Api.Token}).then((res)=>{
            setPreviousMonth(res.data)
            console.log(res.data)
        })
        axios.get(Api.Report.value + "/getTotalIncome/" + date2,{headers: Api.Token}).then(res=>{
            setTotalProfit(res.data)
            console.log(res.data)
            setIsLoading(false)
        })
    }

    const getTotalProfit = () => {
        
    }
    // alert(props.date)
    useEffect(() => {
        getPreviousMonthReport()
        getTotalProfit()
    },[date2])
    // for (let i =0;i<)
    
    const handleSubmit = (e) => {
        const newDate= new Date(`${date}`)
        const name = newDate.toLocaleString('default', { month: 'long' })
        setMonthName(name)
        e.preventDefault()
            
        setDate2(date)
        
        setDate("")
    }
    const dropdownDivStyle ={
        display:"none"
    }
    const dropdownDivStyle2 = {
        display: "block"
    }
    return (<div>
        <form className="form was-validated my-2"   onSubmit={handleSubmit}>
            <div className="d-flex">
                <input type="month" value={date} onChange={e=>setDate(e.target.value)} required minLength={1} className="form-control me-2 w-25" />
                <button className="btn btn-sm btn-primary">send</button>
            </div>
        </form>
        <h2 className="text-capitalize h4">{monthName} Report</h2>
        <div className="table-responsive">
            <table className="table table-sm table-striped table-hover table-bordered">
                <thead className="bg-dark text-light">
                    <tr>
                        <th>Total purchases</th>
                        <th>Total sales</th>
                        <th>Total expenses</th>
                        <th>Total profit</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        totalProfit !=null ? <tr>
                            <td>{totalProfit.totalPurchase}</td>
                            <td>{totalProfit.totalSell}</td>
                            <td>{totalProfit.totalExpenses}</td>
                            <td>{totalProfit.generaProfit}</td>
                        </tr>:<></>
                    }
                </tbody>
            </table>
        </div>
        <div className="table-responsive">
            <table className="table table-hover table-sm table-striped table-bordered">
                <thead className="bg-dark text-light">
                    <tr>
                        <th>Product name</th>
                        <th>Product category</th>
                        <th>Product type</th>
                        <th>Opening stock</th>
                        <th>purchase quantity</th>
                        <th>purchase amount</th>
                        <th>sale quantity</th>
                        <th>sale amount</th>
                        <th>Closing stock</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        previousMonth && previousMonth.map((monthData,index) =>(
                            <tr key={index}>
                                <td>{monthData.product_name}</td>
                                <td>{monthData.category_name}</td>
                                <td>{monthData.type_name}</td>
                                <td>{monthData.opening_stock}</td>
                                <td>{monthData.quantity_suply}</td>
                                <td>{monthData.amount_suply}</td>
                                <td>{monthData.quantity_sell}</td>
                                <td>{monthData.amount_sell}</td>
                                <td>{(monthData.opening_stock +monthData.quantity_suply) - monthData.quantity_sell}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
        <div style={isLoading? dropdownDivStyle2:dropdownDivStyle}>
            <Load />
        </div>
    </div>)

}