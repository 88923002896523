import './bootstrap.css';
import "./App.css"
import Navbar from './components/Navbar';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Products from './components/Products';
import ProductActions from './components/ProductActions';
import Login from './components/Login';
import ImportsForm from './components/AddImports2';
import Expenses from './components/Expense';
import { RequireAuth } from "react-auth-kit";
import Report from './components/report/MainReport';
import { useEffect, useRef, useState } from 'react';
import ExportMultiple2 from './components/AddExport2';
// import { GetAllStock } from './components/GetAllStock';
import AppLogin from './components/AppLogin';
import ManageUsers from './components/ManageUsers';
import Damaged from './components/Damaged';

function App() {
  const token = localStorage.getItem("token")
  const isLogin = localStorage.getItem("isLogin")
  console.log("---------------------------------" + token)
  
  const location = useLocation()
  const path = location.pathname
  console.log(path)

  if(path == "/"){
    localStorage.removeItem("token");
  }

  return (

    <>
      <div className="container-fluid" id='main-page-background'>
        <div className='opacity'></div>
      </div>
      <div className='mainClass'>
        <Navbar />
        <Routes>
          <Route path="/expenses" element={
            // <RequireAuth loginPath="/login">
              <RequireAuth loginPath="/login"><Expenses /></RequireAuth>
            // </RequireAuth>
          }>
          </Route>
          <Route path="/users" element={
            <RequireAuth loginPath="/login">
              <RequireAuth loginPath="/login"><ManageUsers /></RequireAuth>
            </RequireAuth>
          }> 
          </Route>
          <Route path="/actions" element={
            <RequireAuth loginPath="/login"><ProductActions /></RequireAuth>
          }>
          </Route>
          <Route path="/importMultiple" element={
            <RequireAuth loginPath="/login"><ImportsForm /></RequireAuth>
          }>
          </Route>
          
          <Route path="/exportMultiple" element={
            <RequireAuth loginPath="/login"><ExportMultiple2 /></RequireAuth>
          }>
          </Route>
          <Route path="/report" element={
            <RequireAuth loginPath="/login"><Report /></RequireAuth>
          }>
          </Route>
          <Route exact path="/products" element={
            <RequireAuth loginPath="/login">
              <Products />
            </RequireAuth>
          } />
          <Route exact path="/products" element={
            <RequireAuth loginPath="/login">
              <Products />
            </RequireAuth>
          } />
          <Route exact path="/damaged" element={
            <RequireAuth loginPath="/login">
              <Damaged />
            </RequireAuth>
          } />
          {/* <Route exact path="/action" element={
            <RequireAuth loginPath="/login">
              <ProductActions />
            </RequireAuth>
          } /> */}
          <Route path="/login" element={
            <AppLogin />
          }></Route>
          <Route path="/" element={
            <AppLogin />
          }>
          </Route>
        </Routes>
      </div>
    </>
  );
}

export default App;
