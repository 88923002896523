import axios, { Axios } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Api from './Apis';
import Load from './Loading';

const Damaged = () => {
    const localDate = new Date();
    let year = localDate.getFullYear();
    let month = localDate.getMonth() + 1;
    month = month < 10 ? `0${month}`:month
    const fullYear = new Date(`${year}-${month}`);

    const [lastKeyPressTime, setLastKeyPressTime] = useState(null);
    const timeoutRef = useRef(null);
    const [productComponents,setProductComponents] = useState([])
    const [isClicked,setIsClicked] = useState(true)
    const [searchProduct,setSearchProduct] = useState("")
    const [selectedIndex,setSelectedIndex] = useState()
    const [returnedSubmition,setReturnedSubmition] = useState()
    const [openCloseSubmitionValues,setOpenCloseSubmitionValues] = useState(0)
    const [isLoading,setIsLoading] = useState(false)
    
    const [isForm,setIsForm] = useState(0)
    const [date,setDate] = useState("")
    const [newDate,setNewDate] = useState(`${year}-${month}`)
    const [allDamages,setAllDamages] = useState()
    const [monthName, setMonthName] = useState(fullYear.toLocaleString('default', { month: 'long' }))
    

    const [autoRefresh,setAutoRefresh] = useState(false)

    const [inputs, setInputs] = useState([
        {
            "price_id": 0,
            "quantity_damaged": 0
        }
    ]);
    const [currentPage, setCurrentPage] = useState(0);

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedInputs = [...inputs];
        updatedInputs[index] = { ...updatedInputs[index], [name]: value };
        setInputs(updatedInputs);
    };

    const addInputGroup = () => {

        setInputs([
            ...inputs,
            {
                "price_id": 0,
                "quantity_damaged": 0
            }
        ]);
        setCurrentPage(inputs.length);
        setProductComponents([])
        setSearchProduct("")
    };

    const removeInputGroup = (index) => {
        const updatedInputs = inputs.filter((_, i) => i !== index);
        setInputs(updatedInputs);
        setCurrentPage(Math.max(0, currentPage - 1));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true)
        console.log(inputs);
        axios.post(Api.Damages.value + "/add", inputs,{headers: Api.Token}).then(res=> {
            setReturnedSubmition(res.data)
            if(res.data == null){
                alert("done")
            }
            else{
                
                setOpenCloseSubmitionValues(1)
                // alert(returnedSubmition)
            }

            setInputs([
                {
                    "price_id": 0,
                    "quantity_damaged": 0
                }
            ]);
            setProductComponents([])
            setSearchProduct("")
            setIsLoading(false)
        }).catch(()=>{
            localStorage.removeItem("token");
            window.location.replace("/login")
            alert("Something went wrong in authentication. please re-login")
        })
        // window.refles
        // Add your form submission logic here
    };

    const goToPage = (pageIndex) => {
        setCurrentPage(pageIndex);
    };

    const handleKeyPress = () => {
        const currentTime = new Date().getTime();
        setLastKeyPressTime(currentTime);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            // console.log(searchProduct.length)
            // console.log(searchProduct)
            if(searchProduct && searchProduct.length >2){
            setIsLoading(true)
            axios.get(Api.StockAction.value + "/searchQuery/"+ searchProduct,{headers: Api.Token}).then(res=>{
                setProductComponents(res.data)
                setIsLoading(false)
            }).catch(()=>{
                window.location.replace("/login")
                alert("Something went wrong in authentication. please re-login")
            })}
        }, 2000);
    };
    const getAllDamages = () => {
        setIsLoading(true)
        console.log(date)
        // alert(date)
        axios.get(Api.Damages.value + `/getAllByDate/${newDate}`,{headers:Api.Token}).then(res=>{
            setAllDamages(res.data)
            setDate("")
            setIsLoading(false)
        }
        ).catch(()=>{
            localStorage.removeItem("token");
            window.location.replace("/login")
            alert("Something went wrong in authentication. please re-login")
        })
    }
    useEffect(() => {
        // handleProductChange()
        getAllDamages()
        return () => {
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
            }
        };
    },[newDate])
    const dropdownDivStyle ={
        display:"none"
    }
    const dropdownDivStyle2 = {
        display: "block"
    }
    const displaySubmitedReturns = () => {
        return<>
            <div className='p-2'>
                <h3 className='text-center text-bold'>Results</h3>
                {
                    returnedSubmition && returnedSubmition.map((singleReturn,index) => (
                        <div key={index}>
                            <ul>{
                                    singleReturn.includes("successfully")?<li style={{background:"rgb(135, 247, 135)"}}>
                                        {singleReturn}
                                    </li>:
                                    <li style={{background:"rgb(201, 198, 198)"}}>
                                        {singleReturn}
                                    </li>
                                }
                            </ul>
                        </div>
                    ))
                }
                <button 
                className='btn btn-primary btn-sm w-25' 
                onClick={(e) => {setOpenCloseSubmitionValues(0);}
                }>close</button>
            </div>
        </>
    }
    const form = () => {

    }
    const handleSubmitDate = (e) => {
        e.preventDefault()
        // setIsLoading(true)
        // setNewDate(date)
        // console.log("new date is" + newDate)
        // const name = date.toLocaleString('default', { month: 'long' })
        // setMonthName(name)
        // axios.get(Api.Damages.value + "/getAllByDate/"+date,{headers:Api.Token}).then(res=>{
        //     setAllDamages(res.data)
        //     setDate("")

        //     // setAutoRefresh(!autoRefresh)
        //     setIsLoading(false)
        // }
        // ).catch(()=>{
        //     localStorage.removeItem("token");
        //     window.location.replace("/login")
        //     alert("Something went wrong in authentication. please re-login")
        // })

        const newDate= new Date(`${date}`)
        const name = newDate.toLocaleString('default', { month: 'long' })
        setMonthName(name)
        // e.preventDefault()
            
        setNewDate(date)
        
        setDate("")
    }
    return (
    <div className="container bg-light mt-2 rounded">
        <div className='pt-1'>
            <button className="btn btn-primary m-2" onClick={()=>setIsForm(1)}>Add</button>
            <button className="btn btn-primary m-2" onClick={()=>setIsForm(0)}>View</button>
        </div>
        {
            isForm == 1 ? <>
         
                <form onSubmit={handleSubmit} className='form was-validated'>
                    <div className=' rounded'>
                        <h2 className='text-center'>Damaged Products</h2>
                        <div className="row">
                            {/* <div className="col-md-3"></div> */}
                            
                            <div className="col-md-6 bg-light">
                                    {inputs.map((inputGroup, index) => (
                                        index === currentPage && (
                                            <div key={index} >
                                                <div className="row d-flex">
                                                    <label className="form-label">Product</label>
                                                        <div>
                                                            <input
                                                                type="text" required
                                                                onChange={e=>setSearchProduct(e.target.value)}
                                                                onKeyUp={handleKeyPress}
                                                                value={searchProduct}
                                                                className="form-control"
                                                                
                                                            />
                                                            {/* value={inputGroup.price_id} */}
                                                            <select name="price_id" required  onChange={(e) => handleInputChange(index, e)} className="form-select mt-2" id="">
                                                                <option value="">See results</option>
                                                                {
                                                                    productComponents.length > 0? productComponents.map((comp,index) =>(
                                                                        <option key={index} value={comp.productPrice_id}>{comp.product_name}- {comp.category_name} - {comp.type_name}</option>
                                                                    )):<option>no product</option>
                                                                }
                                                            </select>
                                                        </div>
                                                    <div className="col-md-6 first_div">
                                                        <label className="form-label">Quantity</label>
                                                        <input required min="1"
                                                            type="number"
                                                            name="quantity_damaged"
                                                            value={inputGroup.quantity_damaged}
                                                            onChange={(e) => handleInputChange(index, e)}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                    
                                                {/* </div> */}
                                                </div>
                                                
                                                <button
                                                    type="button"
                                                    className="btn btn-danger mt-2"
                                                    onClick={() => removeInputGroup(index)}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        )
                                    ))}
                                    
                                
                            
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-3">
                                        
                                    </div>
                                    <div className="col-md-6">
                                        <div className=' col-md mt-3 '>
                                            <button type="button" className="btn btn-primary mt-5 w-100" onClick={addInputGroup}>
                                                Add Input Group
                                            </button><br />
                                            <button type="submit" className="btn mt-5 w-100 btn-success">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                
                                
                            </div>
                            
                        </div>
                        <div className="pagination mt-3 overflow-scroll">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                disabled={currentPage === 0}
                                onClick={() => goToPage(currentPage - 1)}
                            >
                                Previous
                            </button>
                            {inputs.map((_, index) => (
                                <button
                                    key={index}
                                    type="button"
                                    className={`btn ${currentPage === index ? 'btn-primary' : 'btn-secondary'} mx-1`}
                                    onClick={() => goToPage(index)}
                                >
                                    {index + 1}
                                </button>
                            ))}
                            <button
                                type="button"
                                className="btn btn-secondary"
                                disabled={currentPage === inputs.length - 1}
                                onClick={() => goToPage(currentPage + 1)}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </form>
            </>:<>
                <form className="form was-validated my-2"   onSubmit={handleSubmitDate}>
                    <div className="d-flex">
                        <input type="month" value={date} onChange={e=>setDate(e.target.value)} required minLength={1} className="form-control me-2 w-25" />
                        <button className="btn btn-sm btn-primary">send</button>
                    </div>
                </form>
                <h2 className="text-capitalize h4">{monthName} damages</h2>
                <table className="table table-sm pb-2 table-striped table-bordered">
                    <thead className='bg-dark text-light'>
                        <tr>
                            <th>Date</th>
                            <th>Product name</th>
                            <th>Category name</th>
                            <th>Type name</th>
                            <th>Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allDamages && allDamages.map((damage,index) => (
                                <tr key={index}>
                                    <td>{damage.date_done}</td>
                                    <td>{damage.product_damaged.price_in_product.product_name}</td>
                                    <td>{damage.product_damaged.price_in_category.category_name}</td>
                                    <td>{damage.product_damaged.price_in_type.type_name}</td>
                                    <td>{damage.quantity_damaged}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </>
        }
        <div className="container-fluid dropdown" style={openCloseSubmitionValues == 0?dropdownDivStyle:dropdownDivStyle2}>
            <div className="row">
                <div className="col-lg-12 col-md-8 col-sm-10 col-12">
                    <div className="container w-100 dropDiv">{
                        displaySubmitedReturns()
                    }</div>
                </div>
            </div>                
        </div>
        <div style={isLoading? dropdownDivStyle2:dropdownDivStyle}>
            <Load />
        </div>
    </div>
    );
}

export default Damaged;