import { useEffect, useState } from "react"
import "./css/Product.css"
import axios from "axios"
import Api from "./Apis"
import { FaSearch } from "react-icons/fa";
// import { MdAddCircle } from "react-icons/md";
import { IoListSharp } from "react-icons/io5";
import Load from "./Loading";

const Products = () => {

    const [isSingleProduct,setIsSingleProduct] = useState(0)
    const [allProducts,setAllProducts] = useState()
    const [productWithName,setProductWithName] = useState([])
    const [categories,setCategories] = useState([])
    const [types,setTypes] = useState([])

    const [productName,setProductName] = useState("")
    const [productIdOnCT,setProductIdOnCt] = useState(1)
    const [dropdownDisplay,setDropdownDisplay] = useState(0)
    const [isAddProduct,setIsAddProduct] = useState()
    const [productId,setProductId] = useState(1)

    const [newProductNme,setNewProductName] = useState("")
    const [newCategoryName, setNewCategoryName] = useState('')
    const [newTypeName,setNewTypeName] = useState("")
    const [newPrice,setNewPrice] = useState("")
    const [autoRefresh,setAutoRefresh] = useState(true)
    const [updateProduct,setUpdateProduct] = useState(0)
    const [productToUpdate,setProductToUpdate] = useState(0)
    const [isAddProductOrUpdate,setIsAddProductOrUpdate] = useState(0)
    const [category_id,setCategory_id] = useState(0)
    const [type_id,setType_id] = useState(0)
    const [isLoading,setIsLoading] = useState(true)

    const getAllProducts = (e) => {
        // e.preventDefault()
        setIsLoading(true)
        axios.get(Api.product.value + "/viewAll",{headers:Api.Token}).then(res => {
            setAllProducts(res.data)
            console.log(res.data)
            setIsSingleProduct(false)
            setIsLoading(false)
            // res.data != null? setProductIdOnCt(res.data[0].product_id): null
        }).catch(()=>{
            localStorage.removeItem("token");
            window.location.replace("/login")
            alert("Something went wrong in authentication. please re-login")
        })
    }


    const handleProductClick = (e,product_id) => {
        setIsLoading(true)
        // console.log(product_id)
        axios.get(Api.productAction.value + "/searchCategoryByProduct/"+product_id,{headers:Api.Token}).then(res=> {
            setCategories(res.data)
        }).catch(()=>{
            localStorage.removeItem("token");
            window.location.replace("/login")
            alert("Something went wrong in authentication. please re-login")
        })
        axios.get(Api.productAction.value + "/searchTypeByProduct/"+product_id,{headers:Api.Token}).then(res=> {
            setTypes(res.data)
            setIsLoading(false)
        }).catch(()=>{
            localStorage.removeItem("token");
            window.location.replace("/login")
            alert("Something went wrong in authentication. please re-login")
        })
    }

    const handleSearchSingleClick = (e) => {
        e.preventDefault()
        setIsLoading(true)
        productName != ""?
        axios.post(Api.product.value + "/byName/",{"product_name":productName},{headers:Api.Token} ).then(res => {
            setProductWithName(res.data)
            setAllProducts(res.data)
            setIsSingleProduct(1)
            console.log(res.data)
            setProductIdOnCt(res.data.product_id)
            setProductIdOnCt(res.data.product_id)
            // setCategories([])
            // setTypes([])
            setIsLoading(false)
        }).catch(()=>{
            localStorage.removeItem("token");
            window.location.replace("/login")
            alert("Something went wrong in authentication. please re-login")
        }):alert("please inter product name")
    }
    const getCategories = () => {
        setIsLoading(true)
        axios.get(Api.productAction.value + "/searchCategoryByProduct/" + productIdOnCT,{headers:Api.Token}).then(res=>{
            setCategories(res.data)
            console.log(res.data)
            setIsLoading(false)
        }).catch(()=>{
            localStorage.removeItem("token");
            window.location.replace("/login")
            alert("Something went wrong in authentication. please re-login")
        })
    }
    const getTypes = () => {
        setIsLoading(true)
        axios.get(Api.productAction.value + "/searchTypeByProduct/" + productIdOnCT,{headers:Api.Token}).then(res=>{
            setTypes(res.data)
            console.log(res.data)
            setIsLoading(false)
        }).catch(()=>{
            localStorage.removeItem("token");
            window.location.replace("/login")
            alert("Something went wrong in authentication. please re-login")
        })
    }


    const dropdownDivStyle ={
        display:"none"
    }
    const dropdownDivStyle2 = {
        display: "block"
    }

    useEffect(() => {
        getAllProducts()
        getCategories()
        getTypes()
    },[productIdOnCT,autoRefresh])

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        if(isAddProductOrUpdate == 0){
            if(updateProduct ==0) {
                const formData = {
                    "product_name":newProductNme,
                }    
                axios.post(Api.product.value + "/insertProduct",formData,{headers:Api.Token}).then(res => {
                    // alert(res.data)
                    alert(`Product ${newProductNme} inserted`)
                    setAutoRefresh(!autoRefresh)
                    setNewProductName("")
                    setIsLoading(false)
                }).catch(()=>{
                    localStorage.removeItem("token");
                    window.location.replace("/login")
                    alert("Something went wrong in authentication. please re-login")
                })
            }else{
                const formData = {
                    "product_name":newProductNme,
                    "product_id": productToUpdate
                }
                axios.put(Api.product.value + "/updateProduct",formData,{headers:Api.Token}).then(res => {
                    alert(res.data)
                    setAutoRefresh(!autoRefresh)
                    setNewProductName("")
                    setIsLoading(false)
                }).catch(()=>{
                    localStorage.removeItem("token");
                    window.location.replace("/login")
                    alert("Something went wrong in authentication. please re-login")
                })
            }
        }else if(isAddProductOrUpdate == 1) {
            if(newCategoryName == ""){
                alert('invalid input')
            }
            else{
                const formData = new FormData();
                formData.append("category",newCategoryName)
                axios.put(Api.productAction.value + "/updateCategory/"+ category_id, formData,{headers:Api.Token}).then(res=>{
                    alert("done")
                    setAutoRefresh(!autoRefresh)
                    setNewCategoryName("")
                    setIsLoading(false)
                }).catch(()=>{
                    localStorage.removeItem("token");
                    window.location.replace("/login")
                    alert("Something went wrong in authentication. please re-login")
                })
            }
            // alert(newCategoryName)
        }else{
            if(newTypeName == ""){
                alert('invalid input')
            }
            const formData = new FormData();
            formData.append("type",newTypeName)
            axios.put(Api.productAction.value + "/updateType/"+ type_id, formData,{headers:Api.Token}).then(res=>{
                alert("done")
                setAutoRefresh(!autoRefresh)
                setNewTypeName("")
                setIsLoading(false)
            }).catch(()=>{
                localStorage.removeItem("token");
                window.location.replace("/login")
                alert("Something went wrong in authentication. please re-login")
            })
        }

        setNewCategoryName("")
        setNewTypeName("")
        setNewProductName("")
        setDropdownDisplay(0)
    }

    const addProductForInputs =() => {
        return <>
            <form className="form addProduct was-validated" onSubmit={e => handleSubmit(e)}>
                <h2 className="text-center">{
                    isAddProductOrUpdate == 0 ? <>Add product</>:
                    isAddProductOrUpdate == 1 ? <>Update Category</>:<>Update type</>
                }</h2>
                <div>
                    {
                        isAddProductOrUpdate == 0 ? <>
                            <label className="form-label" htmlFor="product_name">Product Name</label>
                            <input type="text" value={newProductNme} minlength="1" required id="productName" className="form-control" onChange={e=> setNewProductName(e.target.value)} /> 
                        </>:
                        isAddProductOrUpdate == 1 ? <>
                            <label className="form-label" htmlFor="product_name">Category Name</label>
                            <input type="text" id="productName" minlength="1" value={newCategoryName} required className="form-control" onChange={e=> setNewCategoryName(e.target.value)} />
                        
                        </>:<>
                            <label className="form-label" htmlFor="product_name">Type Name</label>
                            <input type="text" id="productName" minlength="1" value={newTypeName} required className="form-control" onChange={e=> setNewTypeName(e.target.value)} />
                           
                        </>
                    }
                </div>
                <div className="mt-3">
                    {/* onClick={(e) => {addNewProduct(e);}}  */}
                    <button className="btn btn-primary w-25">{
                        isAddProductOrUpdate == 0 ? <>add</>:<>update</>
                    }</button>
                    <button className="btn btn-primary w-25" onClick={(e)=>{e.preventDefault();setDropdownDisplay(0)}}>Close</button>
                </div>
            </form>
        </>
    }
    const categoryAndTypeSave = (e) => {
        e.preventDefault()
        setIsLoading(true)
       const formData = {
            "product_id": productId,
            "category_name": newCategoryName,
            "type_name": newTypeName,
            "price": newPrice
        }
        axios.post(Api.productAction.value + "/save",formData,{headers:Api.Token}).then(res=>{
            
           alert(res.data)
           setNewCategoryName("")
           setNewPrice(0)
           setNewTypeName("") 
            setAutoRefresh(!autoRefresh)
            setIsLoading(false)
        }).catch(()=>{
            localStorage.removeItem("token");
            window.location.replace("/login")
            alert("Something went wrong in authentication. please re-login")
        })
        setDropdownDisplay(0)
    }

    const addNewCategoryAndTypeForInputs1 =() => {

        return <>
            <form className="form addProduct was-validated" onSubmit={categoryAndTypeSave}>
                <h2 className="text-center">Add Product</h2>
                <div>
                    <label className="form-label mt-3" htmlFor="product_name">Product Category</label>
                    <input type="text" value={newCategoryName} minlength="1" required id="product_name" className="form-control" onChange={e=>setNewCategoryName(e.target.value)} />
                </div>
                <div>
                    <label className="form-label mt-3" htmlFor="product_name">Product Type</label>
                    <input type="text" value={newTypeName} minlength="1" required id="product_name" className="form-control" onChange={e=> setNewTypeName(e.target.value)} />
                </div>
                <div className="mt-3">
                    <button className="btn btn-primary w-25" >Add</button>
                    <button className="btn btn-primary w-25" onClick={(e)=>{e.preventDefault();setDropdownDisplay(0)}}>Close</button>
                </div>
            </form>
        </>
    }
    return<>
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-4 col-md-6 ">
                    <div className="product scrollable">
                        <h2 className="divHead">Products</h2>
                        <div className="row">
                            <div className="col-12">
                                {/* <form> */}
                                    <div className="buttons">
                                        <div>
                                            {/* {
                                                isSingleProduct == 1? <div>
                                                    <button
                                            className="btn btn-primary btn-sm m-1"
                                             onClick={(e) =>handleSearchSingleClick(e)}
                                           ><FaSearch size="20px" /></button>
                                                 <button
                                             className="btn btn-primary btn-sm m-1"
                                              onClick={(e) =>getAllProducts(e)}
                                            ><IoListSharp title="view all" size="20px" /></button></div>:<button
                                            className="btn btn-primary btn-sm"
                                             onClick={(e) =>handleSearchSingleClick(e)}
                                           ><FaSearch size="20px" /></button>
                                            } */}
                                            
                                        </div>
                                        <div>
                                        
                                        </div>
                                        <div>
                                            <button className="btn btn-primary btn-sm"onClick={(e)=>{
                                            e.preventDefault();
                                            setIsAddProduct(1);
                                            setDropdownDisplay(1);
                                            setIsAddProductOrUpdate(0)
                                            }}>Add Product</button>
                                        </div>
                                        
                                    </div>
                                    {/* <input
                                     type="text"
                                     className="form-control" required
                                      name="searchProduct"
                                       placeholder="Enter product name"
                                        onChange={e => setProductName(e.target.value)} /> */}
                                {/* </form> */}
                            </div>
                            <div className="col-12 productTable">
                                <table className="table table-sm table-hover">
                                    <thead>
                                    <tr>
                                        <th>Product Name</th>
                                        <th>Components & types</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                           allProducts && allProducts.map((product,index) => {
                                                const product_id = product.product_id
                                                return <tr key={index}>
                                                    <td>{product.product_name}</td>
                                                    <td>
                                                    <button 
                                                    className="btn btn-primary btn-sm m-1" 
                                                    onClick={(e) => {
                                                        handleProductClick(e,product_id)
                                                        
                                                    }}>view</button>
                                                    <button 
                                                    className="btn btn-primary btn-sm m-1"onClick={(e)=>{
                                                    e.preventDefault();
                                                    setIsAddProduct(0);
                                                    setDropdownDisplay(1)
                                                    setProductName(product.product_name)
                                                    setProductId(product_id)
                                                    }}>Add New</button>
                                                    </td>
                                                    {/* <td className="d-flex">
                                                        <button 
                                                        onClick={(e)=> {
                                                            setDropdownDisplay(1);
                                                            setIsAddProduct(1);
                                                            setUpdateProduct(1);
                                                            setProductToUpdate(product_id)}} 
                                                            className="btn btn-sm m-1"
                                                            title="update product"
                                                        >
                                                        <GrUpdate size="18px" /></button>
                                                        <button 
                                                        onClick={(e)=> handleProductDelete(e,product_id)} 
                                                        className="btn  btn-sm m-1" title="delete product"><MdDelete size="23px"/></button>
                                                    </td> */}
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="col-lg-4 col-md-6 ">
                    <div className="product scrollable">
                        <h2 className="divHead">Product Categories</h2>
                        <div className="row">
                            <div className="col-12 productTable">
                                <table className="table table-sm table-hover">
                                    <thead>
                                    <tr>
                                        <th>Category No</th>
                                        <th>Category Name</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            categories && categories.map((categories,index) => (
                                                <tr key={index}>
                                                    <td>{index+1}</td>
                                                    <td>{categories.cetegory_name}</td>
                                                    {/* <td className="d-flex">
                                                        <button 
                                                        onClick={(e)=> {
                                                            setCategory_id(categories.category_id);
                                                            setIsAddProductOrUpdate(1);
                                                            setIsAddProduct(1);
                                                            setDropdownDisplay(1);
                                                        }}
                                                            className="btn btn-sm m-1"
                                                            title="update product"
                                                        >
                                                        <GrUpdate size="18px" /></button>
                                                        <button 
                                                        onClick={()=> categoryDelete(categories.category_id)} 
                                                        className="btn  btn-sm m-1" title="delete product"><MdDelete size="23px"/></button>
                                                    </td> */}
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="product scrollable">
                        <h2 className="divHead">Product Types</h2>
                        <div className="row">
                            <div className="col-12 productTable">
                                <table className="table table-sm table-hover">
                                    <thead>
                                    <tr>
                                        <th>Type No</th>
                                        <th>Type Name</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            types && types.map((type,index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{type.type_name}</td>
                                                    {/* <td className="d-flex">
                                                        <button 
                                                        onClick={(e)=> {
                                                            setType_id(type.type_id)
                                                            setIsAddProductOrUpdate(2);
                                                            setIsAddProduct(1);
                                                            setDropdownDisplay(1);
                                                        }} 
                                                            className="btn btn-sm m-1"
                                                            title="update product"
                                                        >
                                                        <GrUpdate size="18px" /></button>
                                                        <button 
                                                        onClick={(e)=> typeDelete(type.type_id)} 
                                                        className="btn  btn-sm m-1" title="delete product"><MdDelete size="23px"/></button>
                                                    </td> */}
                                                </tr>
                                            ))
                                        }
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <div className="container-fluid dropdown" style={dropdownDisplay == 0?dropdownDivStyle:dropdownDivStyle2}>
            <div className="row">
                <div className="col-lg-4 col-md-2 col-1"></div>
                <div className="col-lg-4 col-md-8 col-sm-10 col-12">
                    <div className="container w-100 dropDiv">{
                        isAddProduct == 1 ? addProductForInputs():addNewCategoryAndTypeForInputs1()
                    }</div>
                </div>
            </div>                
        </div>
        <div style={isLoading? dropdownDivStyle2:dropdownDivStyle}>
            <Load />
        </div>
    </>
}

export default Products